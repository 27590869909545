import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { WidgetComponentInterface } from '../widget-component.interface';
import { AggregateMetaData, DomainModelMetaData, EnumResource, GenericServiceResponse, PropertyMetaData, RouteChangeParams, RoutingService, ServiceResponse, ToastMessageService, ZoomResultsDateTimeCellRendererComponent, ZoomResultsDateTimeOffsetCellRendererComponent } from '@nts/std';
import { WidgetResultSetFilterDataArgDto } from '../../../domain-models/dto/widget-result-set-filter-data-arg-dto';
import { DataLevel } from '../../../domain-models/enum/data-level';
import { WidgetFullScreenComponent } from '../widget-full-screen-component';
import { MetaDataUtils } from '@nts/std';
import { from, Observable, of, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, map, tap } from 'rxjs/operators';
import { WidgetFeatures } from '../widget-component';
import { GridApi, GridOptions, ColDef, ColumnApi, ColumnState } from 'ag-grid-community';
import { LogService } from '@nts/std/src/lib/utility';
import { WidgetZoomResultValueDto } from 'src/app/dash-board/domain-models/dto/widget-zoom-result-value';
import { OutputDataOrderDto } from 'src/app/dash-board/domain-models/dto/output-data-order-dto';
import { ZoomResultsDefaultCellRendererComponent } from '@nts/std';
import { ZoomResultsBoolCellRendererComponent } from '@nts/std';
import { ZoomColumnInfo } from '@nts/std';
import { AgGridModule } from 'ag-grid-angular';
import { NgIf } from '@angular/common';


@UntilDestroy()
@Component({
    selector: 'nts-zoom-result-widget',
    templateUrl: './zoom-result-widget.component.html',
    styleUrls: ['./zoom-result-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AgGridModule,
        NgIf
    ]
})
export class ZoomResultWidgetComponent extends WidgetFullScreenComponent implements WidgetComponentInterface {


    public gridOptions: GridOptions;
    private gridApi: GridApi;
    private gridColumnApi: ColumnApi;
    private updateGridStateRequested: Subject<void> = new Subject<void>();
    private currentTake = 0;
    private currentSkip = 0;
    private noData = false;
    private metaData: AggregateMetaData;
    private layout: OutputDataOrderDto[];
    private enumDictionaries: Map<number, EnumResource[]>;

    title: string;
    subTitle: string;
    dataLevel = DataLevel;

    columnDefs = [];

    rowData = [];

    components = {
        loadingRenderer: (params) => '<img src="assets/images/nts/widget/loading.gif">'
    }

    constructor(
        private cd: ChangeDetectorRef,
        toastMessageService: ToastMessageService,
        private routingService: RoutingService
    ) {
        super(toastMessageService, cd);
        this.gridOptions = {} as GridOptions;
        this.gridOptions.rowBuffer = 0;
        this.gridOptions.rowSelection = 'single';
        this.gridOptions.rowModelType = 'infinite';
        // this.gridOptions.paginationPageSize = 20;
        // this.gridOptions.cacheOverflowSize = 2;
        this.gridOptions.maxConcurrentDatasourceRequests = 1;
        // this.gridOptions.infiniteInitialRowCount = 20;
        // this.gridOptions.maxBlocksInCache = 10;

    }

    // mappa per memorizzare l'ordine degli elementi
    private orderMap: { [key: string]: number } = {};
    private originalOrderColId: string[] = [];

    private async updateFieldsFromLabels(widgetZoomResultValueDto: WidgetZoomResultValueDto) {
        const cellValueGetter = (params: any, index: number) => {
            const data = params.data;
            if (data) {
                return data?.[index] ?? '';
            } else {
                return null;
            }
        };

        this.originalOrderColId = widgetZoomResultValueDto.layout.map((outputDataOrderDto: OutputDataOrderDto) => this.getWidgetUniqueKey() + '_' + outputDataOrderDto.propertyName);

        const columnDefs = widgetZoomResultValueDto.layout.map((outputDataOrderDto: OutputDataOrderDto) => ({ colId: this.getWidgetUniqueKey() + '_' + outputDataOrderDto.propertyName, ...outputDataOrderDto }));

        const savedState = await this.getFeatureForCurrentWidget<ColumnState[]>(
            WidgetFeatures.GridState
        );

        if (savedState?.length > 0) {

            // Resetto la mappa
            this.orderMap = {};

            for (const [index, item] of savedState.entries()) {
                this.orderMap[item.colId] = index;
            }

            // Ordinare l'array columnDefs in base alla mappa creata
            columnDefs.sort((a, b) => {
                const orderA = this.orderMap[a.colId] !== undefined ? this.orderMap[a.colId] : Infinity;
                const orderB = this.orderMap[b.colId] !== undefined ? this.orderMap[b.colId] : Infinity;
                return orderA - orderB;
            });
        }

        this.columnDefs =

            columnDefs.map((outputDataOrderDto: (OutputDataOrderDto & { colId: string }), index: number) => ({

                /**
                 * The unique ID to give the column.
                 * This is optional. If missing, the ID will default to the field.
                 * If both field and colId are missing, a unique ID will be generated.
                 * This ID is used to identify the column in the API for sorting, filtering etc.
                 */
                colId: outputDataOrderDto.colId,

                //field: outputDataOrderDto.propertyName,

                headerName: outputDataOrderDto.caption,
                field: index.toString(),
                cellRendererParams: {
                    columnInfo: this.getColumnInfo(outputDataOrderDto, index)
                },

                resizable: true,

                sortable: false,

                /**
                 * Set to true to block making column visible / hidden via the UI
                 * (API will still work). Default: false
                 */
                lockVisible: true,

                /**
                 * Function or expression. Gets the value from your data for display.
                 */
                valueGetter: (params: any) => cellValueGetter(params, index),

                cellRenderer: this.getCellRendererFramework(
                    outputDataOrderDto.propertyName
                ),
                /**
                 * Callback to select which cell renderer to be used for a given row within the same column.
                 */
                cellRendererSelector: (params: any) => {

                    if (params.value == null) {
                        return {
                            component: 'loadingRenderer',
                        };
                    }
                    return null;
                }
            } as ColDef));
    }

    private buildEnumDictionaries(domainModelMetaData: DomainModelMetaData) {

        this.enumDictionaries = new Map<number, EnumResource[]>();

        this.checkEnums(domainModelMetaData, '');
    }

    private checkEnums(domainModelMetaData: DomainModelMetaData, currentPath: string, processedExternals: string[] = []) {

        domainModelMetaData.enums.forEach(en => {
            const columnIndex = this.layout.indexOf(this.layout.filter(item => {
                const rootPath = item.propertyName.split('.').slice(0, -1).join('.');
                return (rootPath ?? '') === currentPath && item.propertyName === en.name;
            })[0]);

            if (columnIndex >= 0) {
                this.enumDictionaries.set(columnIndex, en.valuesResource);
            }
        });

        domainModelMetaData.internalCollections.forEach(internalCollection => {
            this.checkEnums(internalCollection.dependentMetaData,
                currentPath.length > 0 ? (currentPath + '.' + internalCollection.principalPropertyName) : internalCollection.principalPropertyName,
                processedExternals);
        });

        domainModelMetaData.internalRelations.forEach(internalRelation => {
            this.checkEnums(
                internalRelation.dependentMetaData,
                currentPath.length > 0 ? (currentPath + '.' + internalRelation.principalPropertyName) : internalRelation.principalPropertyName,
                processedExternals);
        });

        domainModelMetaData.externals.forEach(external => {
            if (processedExternals.indexOf(external.dependentAggregateMetaData.rootFullName) === -1) {
                processedExternals.push(external.dependentAggregateMetaData.rootFullName);
                this.checkEnums(external.dependentAggregateMetaData.rootMetaData,
                    currentPath.length > 0 ? (currentPath + '.' + external.principalPropertyName) : external.principalPropertyName,
                    processedExternals);
            }
        });
    }

    // Qui entro solo dopo la prima chiamata
    protected initView(result: WidgetZoomResultValueDto, refreshigData: boolean): Observable<boolean> {
        if (result?.title && result?.result) {
            this.title = result.title;
            this.modalTitle = result.title;
            this.subTitle = result.subTitle;
            this.metaData = result.metaData;
            this.layout = result.layout;


            this.buildEnumDictionaries(this.metaData.rootMetaData);

            return from(this.updateFieldsFromLabels(result)).pipe(
                tap(() => this.cd.detectChanges()),
                map(() => true)
            );

        } else {
            this.title = this.vm.description.value;
        }

        this.cd.detectChanges();
        return of(true);
    }

    private getColumnInfo(outputDataOrderDto: OutputDataOrderDto, index: number): ZoomColumnInfo {
        const columnInfo = new ZoomColumnInfo(outputDataOrderDto.propertyName, index);
        columnInfo.header = outputDataOrderDto.caption;
        columnInfo.isVisible = outputDataOrderDto.isVisible;
        columnInfo.position = outputDataOrderDto.position;
        columnInfo.propertyTypeName = this.getPropertyMetaData(outputDataOrderDto.propertyName)?.name;
        //column.settings = SettingsType.Default;
        return columnInfo
    }

    private getCellRendererFramework(propertyName: string) {
        const propertyMetaData = this.getPropertyMetaData(propertyName)
        switch (propertyMetaData?.getType()) {
            case 'Bool':
                return ZoomResultsBoolCellRendererComponent;
            case 'DateTime':
                return ZoomResultsDateTimeCellRendererComponent;
            case 'DateTimeOffset':
                return ZoomResultsDateTimeOffsetCellRendererComponent;
            default:
                return ZoomResultsDefaultCellRendererComponent;
        }
    }

    getPropertyMetaData(propertyName: string): PropertyMetaData {
        return MetaDataUtils.getPropertyMetaDataFromPath(this.metaData.rootMetaData, propertyName);
    }


    private isJsonString(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public rowDoubleClicked(e: any) {
        const data = e.data as any[];
        if (this.metaData.rootFullName) {
            const routeChangeParams = new RouteChangeParams();
            routeChangeParams.rootModelFullName = this.metaData.rootFullName;
            routeChangeParams.inBlank = true;

            const serializedIdentity = this.getJsonIdentity(data);
            if (serializedIdentity && this.isJsonString(serializedIdentity)) {
                routeChangeParams.routeParam = RoutingService.encodeUrl(serializedIdentity);
            }

            routeChangeParams.queryParams = '?collapse-menu=true';

            this.routingService.routeChangeRequested.next(routeChangeParams)
        }
    }

    private getJsonIdentity(item: Object) {
        const identity = {};
        this.metaData.rootMetaData.identityNames.forEach((propertyName: string) => {
            const propertyNameInCamelCase = MetaDataUtils.toCamelCase(propertyName);
            const columnIndex = this.layout.findIndex(column => column.propertyName === propertyName);
            const columnInfo = this.layout[columnIndex];
            let val = item[columnIndex];
            // todo se la property è di tipo enum, devo riconvertire in int la stringa
            /*if (this.enumDictionaries.has(columnInfo.orderIndex)) {
              const enumValues = this.enumDictionaries.get(columnInfo.orderIndex);
              enumValues.forEach((entry: EnumResource, k) => {
                if (entry === val) {
                  val = k;
                }
              });
            }*/
            identity[propertyNameInCamelCase] = val;
        });
        return JSON.stringify(identity);
    }


    getRowDataWithOrderedColumn(data: (string | number | boolean)[][]): any {
        return data.map((row) => {
            const orderedRowWithIndex = row.map((value, index) => ({ value, index })).sort((a, b) => {
                const orderA = this.orderMap[this.originalOrderColId[a.index]] !== undefined ? this.orderMap[this.originalOrderColId[a.index]] : Infinity;
                const orderB = this.orderMap[this.originalOrderColId[b.index]] !== undefined ? this.orderMap[this.originalOrderColId[b.index]] : Infinity;
                return orderA - orderB;
            });
            return orderedRowWithIndex.map((f) => f.value);
        });
    }

    onGridReady(params) {
        this.gridApi = params.api as GridApi;
        this.gridColumnApi = params.columnApi;

        this.updateGridStateRequested
            .pipe(
                untilDestroyed(this),
                debounceTime(500)
            )
            .subscribe(async () => {
                let savedState = this.gridColumnApi.getColumnState();
                await this.setFeatureForCurrentWidget(
                    WidgetFeatures.GridState, savedState);
            })

        this.reloadGridState();

        let init = true;
        const dataSource = {
            rowCount: null,
            getRows: async (params) => {
                LogService.debug(
                    'asking for ' + params.startRow + ' to ' + params.endRow
                );

                this.currentTake = params.endRow - params.startRow + 1;
                this.currentSkip = params.startRow;
                const response = (await this.loadData(!init).toPromise()) as WidgetZoomResultValueDto;

                if (params.startRow === 0) {
                    this.noData = response.result?.length == null;
                }

                if (init) {
                    await this.initView(response, false).toPromise();
                }

                if (response) {
                    const rowsThisPage = this.normalizeRow(response.result);
                    let lastRow = -1;
                    if (response.result.length + params.startRow <= params.endRow) {
                        lastRow = response.result.length + params.startRow;
                    }
                    params.successCallback(this.getRowDataWithOrderedColumn(rowsThisPage), lastRow);
                    if (init) {
                        this.reloadGridState();
                        init = false;
                    }
                } else {
                    params.failCallback();
                    LogService.warn(
                        'Pagination API failed!', response
                    );
                }
                this.forceHideLoader.next();
                this.cd.detectChanges();
            },
        };
        params.api.setDatasource(dataSource);
    }

    // Faccio l'override per evitare di forzare l'hide del loader
    public override initComponent(refreshigData = false): Observable<boolean> {
        if (refreshigData) {
            // Visulizzo il loader per forzare l'aggiornamento della griglia
            this.refreshInProgress$.next(true);
            this.cd.detectChanges();
            if (this.gridApi) {
                this.gridApi.purgeInfiniteCache();
            }
            if (this.noData) {
                this.refreshInProgress$.next(false);
                this.cd.detectChanges();
            }
        }
        return of(true);
    }

    protected getLoadDataObservable<WidgetZoomResultValueDto>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetZoomResultValueDto>> {
        const widgetDataArg = this.getWidgetDataArg();
        const widgetResultSetFilterDataArgDto = new WidgetResultSetFilterDataArgDto;
        widgetResultSetFilterDataArgDto.dashBoardWidgetIdentity = widgetDataArg.dashBoardWidgetIdentity;
        widgetResultSetFilterDataArgDto.identity = widgetDataArg.identity;
        widgetResultSetFilterDataArgDto.jsonAPIArg = widgetDataArg.jsonAPIArg;
        widgetResultSetFilterDataArgDto.skip = this.currentSkip;
        widgetResultSetFilterDataArgDto.take = this.currentTake;
        return this.getApiClient().getZoomResult(widgetResultSetFilterDataArgDto, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
    }

    private async reloadGridState() {
        if (this.gridColumnApi) {
            const savedState = await this.getFeatureForCurrentWidget<ColumnState[]>(
                WidgetFeatures.GridState);
            if (savedState?.length > 0) {
                // restore the column state
                this.gridColumnApi.applyColumnState({ state: savedState });
            }
        }
    }

    private normalizeRow(rows: any[]) {
        if (this.enumDictionaries != null) {
            rows.forEach(row => {
                this.enumDictionaries.forEach((value, colIndex) => {
                    const enumResources = this.enumDictionaries.get(colIndex);
                    if (enumResources) {
                        const enumResource = enumResources.find((e: EnumResource) => e.enumValue === row[colIndex]);
                        if (enumResource) {
                            row[colIndex] = enumResource.displayValue;
                        } else {
                            LogService.warn(`ERRORE: enumValue ${row[colIndex]} non trovato!`, enumResource, this.enumDictionaries);
                        }
                    } else {
                        LogService.warn(`ERRORE: indice ${colIndex} non trovato in enumDictionaries!`, this.enumDictionaries);
                    }
                });
            });
        }
        return rows;
    }

    onSortChanged(e) {
        // TODO
        // console.log('Event Sort Changed', e);
    }

    onColumnResized(e) {
        this.updateGridStateRequested.next();

    }

    onColumnMoved(e) {
        this.updateGridStateRequested.next();
    }
}
