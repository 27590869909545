import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { WidgetComponentInterface } from '../widget-component.interface';
import { GenericServiceResponse, NTSTranslatePipe, RouteChangeParams, RoutingService, ServiceResponse, ToastMessageService } from '@nts/std';
import { WidgetResultSetFilterDataArgDto } from '../../../domain-models/dto/widget-result-set-filter-data-arg-dto';
import { WidgetIconResultSetValueDto } from '../../../domain-models/dto/widget-icon-result-set-value-dto';
import { ResultSetIconItemDto } from '../../../domain-models/dto/result-set-icon-item-dto';
import { DataLevel } from '../../../domain-models/enum/data-level';
import { WidgetFullScreenComponent } from '../widget-full-screen-component';
import mapKeys from 'lodash-es/mapKeys';
import { MetaDataUtils } from '@nts/std';
import { firstValueFrom, from, Observable, of, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, map, tap } from 'rxjs/operators';
import { WidgetFeatures } from '../widget-component';
import { GridApi, GridOptions, ColDef, ColumnApi, ColumnState } from 'ag-grid-community';
import { LogService } from '@nts/std/src/lib/utility';
import { AgGridModule } from 'ag-grid-angular';
import { DatePipe, NgIf } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'nts-icon-result-set-widget',
  templateUrl: './icon-result-set-widget.component.html',
  styleUrls: ['./icon-result-set-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AgGridModule,
    NgIf,
    NTSTranslatePipe,
    DatePipe
  ]
})
export class IconResultSetWidgetComponent extends WidgetFullScreenComponent implements WidgetComponentInterface {

  public gridOptions: GridOptions;
  private gridApi: GridApi;
  override fontColor = 'black';
  private gridColumnApi: ColumnApi;
  private updateGridStateRequested: Subject<void> = new Subject<void>();
  private currentTake = 0;
  private currentSkip = 0;
  private noData = false;

  title: string;
  subTitle: string;
  dataLevel = DataLevel;

  columnDefs = [];

  rowData = [];

  components = {
    cellWithStatusRenderer: (params: any) => {
      return `
            <div class="item-container">
                <div class="item-status item-status-level-${DataLevel[params.dataLevel]}"></div>
                <div class="item-name">${params.value ?? ''}</div>
            </div>`;
    },
    loadingRenderer: (params) => '<img src="assets/images/nts/widget/loading.gif">'
  }

  constructor(
    private cd: ChangeDetectorRef,
    toastMessageService: ToastMessageService,
    private routingService: RoutingService
  ) {
    super(toastMessageService, cd);
    this.gridOptions = {} as GridOptions;
    this.gridOptions.rowBuffer = 0;
    this.gridOptions.rowSelection = 'single';
    this.gridOptions.rowModelType = 'infinite';
    // this.gridOptions.paginationPageSize = 20;
    // this.gridOptions.cacheOverflowSize = 2;
    this.gridOptions.maxConcurrentDatasourceRequests = 1;
    // this.gridOptions.infiniteInitialRowCount = 20;
    // this.gridOptions.maxBlocksInCache = 10;

  }

  // mappa per memorizzare l'ordine degli elementi
  private orderMap: { [key: string]: number } = {};
  private originalOrderColId: string[] = [];

  private async updateFieldsFromLabels(labels: string[]) {
    const cellValueGetter = (params: any, index: number) => {
      const data = params.data as ResultSetIconItemDto;
      if (data) {
        return data?.cellList[index]?.dataValue ?? '';
      } else {
        return null;
      }
    };

    this.originalOrderColId = labels.map((field) => this.getWidgetUniqueKey() + '_' + field);

    // Creo dalla lista delle etichette, un oggetto complesso che ha sia gli id delle colonne che il nome del campo
    const columnDefs = labels.map((field) => ({ colId: this.getWidgetUniqueKey() + '_' + field, field }));

    const savedState = await this.getFeatureForCurrentWidget<ColumnState[]>(
      WidgetFeatures.GridState
    );

    if (savedState?.length > 0) {

      // Resetto la mappa
      this.orderMap = {};

      for (const [index, item] of savedState.entries()) {
        this.orderMap[item.colId] = index;
      }

      // Ordinare l'array columnDefs in base alla mappa creata
      columnDefs.sort((a, b) => {
        const orderA = this.orderMap[a.colId] !== undefined ? this.orderMap[a.colId] : Infinity;
        const orderB = this.orderMap[b.colId] !== undefined ? this.orderMap[b.colId] : Infinity;
        return orderA - orderB;
      });
    }

    this.columnDefs =

      columnDefs.map(({ colId, field }, index: number) => ({

        /**
         * The unique ID to give the column.
         * This is optional. If missing, the ID will default to the field.
         * If both field and colId are missing, a unique ID will be generated.
         * This ID is used to identify the column in the API for sorting, filtering etc.
         */
        colId,

        field,

        resizable: true,

        sortable: false,

        /**
         * Set to true to block making column visible / hidden via the UI
         * (API will still work). Default: false
         */
        lockVisible: true,

        /**
         * Function or expression. Gets the value from your data for display.
         */
        valueGetter: (params: any) => cellValueGetter(params, index),

        /**
         * Callback to select which cell renderer to be used for a given row within the same column.
         */
        cellRendererSelector: (params: any) => {

          if (params.value == null) {
            return {
              component: 'loadingRenderer',
            };
          }

          const data = params.data as ResultSetIconItemDto;

          if (data?.cellList[index]?.dataLevel && (data?.cellList[index].dataLevel !== DataLevel.None)) {
            return {
              component: 'cellWithStatusRenderer',
              params: { dataLevel: data.cellList[index]?.dataLevel }
            };
          } else {
            return null;
          }
        }
      } as ColDef));
  }

  // Qui entro solo dopo la prima chiamata
  protected initView(result: WidgetIconResultSetValueDto, refreshigData: boolean): Observable<boolean> {

    if (result?.title && result?.labels) {
      this.title = result.title;
      this.modalTitle = result.title;
      this.subTitle = result.subTitle;

      return from(this.updateFieldsFromLabels(result.labels)).pipe(
        tap(() => this.cd.detectChanges()),
        map(() => true)
      );

    } else {
      this.title = this.vm.description.value;
    }

    this.cd.detectChanges();
    return of(true);
  }

  private isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  public rowDoubleClicked(e: any) {
    const data = e.data as ResultSetIconItemDto;
    if (data.rootModelFullName) {
      const routeChangeParams = new RouteChangeParams();
      routeChangeParams.rootModelFullName = data.rootModelFullName;
      routeChangeParams.inBlank = true;

      if (data.serializedIdentity && this.isJsonString(data.serializedIdentity)) {
        // Transformo l'oggetto con proprietà in PascalCase in CamelCase
        const obj = mapKeys(
          JSON.parse(data.serializedIdentity),
          (value: any, key: string) => MetaDataUtils.toCamelCase(key));


        routeChangeParams.routeParam = RoutingService.encodeUrl(JSON.stringify(obj));
      }

      routeChangeParams.queryParams = '?collapse-menu=true';

      this.routingService.routeChangeRequested.next(routeChangeParams)
    }
  }

  private init = true

  getRowDataWithOrderedCellDto(data: ResultSetIconItemDto[]): ResultSetIconItemDto[] {
    return data.map((row) => {
      row.cellList = row.cellList.map((c, index) => ({ ...c, index })).sort((a, b) => {
        const orderA = this.orderMap[this.originalOrderColId[a.index]] !== undefined ? this.orderMap[this.originalOrderColId[a.index]] : Infinity;
        const orderB = this.orderMap[this.originalOrderColId[b.index]] !== undefined ? this.orderMap[this.originalOrderColId[b.index]] : Infinity;
        return orderA - orderB;
      });
      return row;
    });
  }

  onGridReady(params) {
    this.gridApi = params.api as GridApi;
    this.gridColumnApi = params.columnApi;

    this.updateGridStateRequested
      .pipe(
        untilDestroyed(this),
        debounceTime(500)
      )
      .subscribe(async () => {
        let savedState = this.gridColumnApi.getColumnState();
        await this.setFeatureForCurrentWidget<ColumnState[]>(
          WidgetFeatures.GridState, savedState);
      })

    this.reloadGridState();

    const dataSource = {
      rowCount: null,
      getRows: async (params) => {
        LogService.debug(
          'asking for ' + params.startRow + ' to ' + params.endRow
        );

        this.currentTake = params.endRow - params.startRow + 1;
        this.currentSkip = params.startRow;
        const response = (await this.loadData(!this.init).toPromise()) as WidgetIconResultSetValueDto;

        if (params.startRow === 0) {
          this.noData = response?.items?.length == null;
        }

        if (this.init) {
          await this.initView(response, false).toPromise();
        }

        if (response) {
          const rowsThisPage = response.items;
          let lastRow = -1;
          if ((response?.items?.length ?? 0) + params.startRow <= params.endRow) {
            lastRow = (response?.items?.length ?? 0) + params.startRow;
          }
          params.successCallback(this.getRowDataWithOrderedCellDto(rowsThisPage), lastRow);
          if (this.init) {
            this.reloadGridState();
            this.init = false;
          }
        } else {
          params.failCallback();
          LogService.warn(
            'Pagination API failed!', response
          );
        }
        this.forceHideLoader.next();
        this.cd.detectChanges();
      },
    };
    params.api.setDatasource(dataSource);
  }

  protected override async refreshData() {
    if (this.refreshInProgress$.value === false) {
      this.refreshInProgress$.next(true);
      // Per resettare gli errori devo passare un ServiceResponse
      this.vm.loadingErrorChanged.next(new ServiceResponse());

      // Forzo la prima chiamata
      //
      this.currentTake = 101;
      this.currentSkip = 0;

      await firstValueFrom(this.getLoadDataObservable(true));
      this.refreshInProgress$.next(false);


      this.cd.detectChanges();
      this.init = true;
      await firstValueFrom(this.initComponent(true).pipe(
        untilDestroyed(this),
        tap(() => {
          this.cd.detectChanges();
        })
      ))
    }
  }

  // Faccio l'override per evitare di forzare l'hide del loader
  public override initComponent(refreshigData = false): Observable<boolean> {
    if (refreshigData) {
      if (this.gridApi) {
        this.gridApi.purgeInfiniteCache();
        this.gridApi.refreshInfiniteCache();
      }
      if (this.noData) {
        this.refreshInProgress$.next(false);
        this.cd.detectChanges();
      }
    }
    return of(true).pipe(tap(() => {
      if (!refreshigData) {
        setTimeout(() => {
          this.refreshData()
        }, 500)
      }
    }));
  }

  protected getLoadDataObservable<WidgetIconResultSetValueDto>(refreshigData: boolean): Observable<GenericServiceResponse<WidgetIconResultSetValueDto>> {
    const widgetDataArg = this.getWidgetDataArg();
    const widgetResultSetFilterDataArgDto = new WidgetResultSetFilterDataArgDto;
    widgetResultSetFilterDataArgDto.dashBoardWidgetIdentity = widgetDataArg.dashBoardWidgetIdentity;
    widgetResultSetFilterDataArgDto.identity = widgetDataArg.identity;
    widgetResultSetFilterDataArgDto.jsonAPIArg = widgetDataArg.jsonAPIArg;
    widgetResultSetFilterDataArgDto.skip = this.currentSkip;
    widgetResultSetFilterDataArgDto.take = this.currentTake;
    return this.getApiClient().getWidgetIconResultSet(widgetResultSetFilterDataArgDto, refreshigData).pipe(tap((r) => this.lastUpdate = r.cache?.timestamp), map(r => r.response)) as any;
  }

  private async reloadGridState() {
    if (this.gridColumnApi) {
      const savedState = await this.getFeatureForCurrentWidget<ColumnState[]>(
        WidgetFeatures.GridState);
      if (savedState?.length > 0) {
        // restore the column state
        this.gridColumnApi.applyColumnState({ state: savedState });
      }
    }
  }

  onSortChanged(e) {
    // TODO
    // console.log('Event Sort Changed', e);
  }

  onColumnResized(e) {
    this.updateGridStateRequested.next();

  }

  onColumnMoved(e) {
    this.updateGridStateRequested.next();
  }
}
